@font-face {
  font-family: "Open Sans";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("assets/fonts/OpenSans-Light.eot");
  src: local("Open Sans"), url("assets/fonts/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("assets/fonts/OpenSans-Light.woff") format("woff"), url("assets/fonts/OpenSans-Light.ttf") format("truetype"), url("assets/fonts/OpenSans-Light.svg#svgFontName") format("svg"); }

@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("assets/fonts/OpenSans-Regular.eot");
  src: local("Open Sans"), url("assets/fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("assets/fonts/OpenSans-Regular.woff") format("woff"), url("assets/fonts/OpenSans-Regular.ttf") format("truetype"), url("assets/fonts/OpenSans-Regular.svg#svgFontName") format("svg"); }

@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("assets/fonts/OpenSans-SemiBold.eot");
  src: url("assets/fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("assets/fonts/OpenSans-SemiBold.woff") format("woff"), url("assets/fonts/OpenSans-SemiBold.ttf") format("truetype"), url("assets/fonts/OpenSans-SemiBold.svg#svgFontName") format("svg"); }

@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("assets/fonts/OpenSans-Bold.eot");
  src: url("assets/fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("assets/fonts/OpenSans-Bold.woff") format("woff"), url("assets/fonts/OpenSans-Bold.ttf") format("truetype"), url("assets/fonts/OpenSans-Bold.svg#svgFontName") format("svg"); }

/*
* ====== Base ======
*/
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media (min-width: 1024px) and (min-height: 741px) {
    body.overflow-hidden {
      overflow: hidden; } }

html,
body,
#root {
  min-height: 100%; }

.formDescription {
  font-size: 20px;
  color: #373737;
  margin: 20px 0 40px;
  line-height: 1.5; }
  .formDescription b {
    font-weight: 600; }

.formGoback {
  width: 55px;
  height: 18px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #373737;
  margin: 20px 50px 40px 0;
  text-decoration: underline; }

@media only screen and (max-width: 991px) {
  .MuiTypography-body1 {
    font-size: 14px !important; } }

.formControl {
  width: 100%;
  margin: 12px 0 24px !important;
  display: flex !important;
  position: relative; }
  .formControl > div {
    margin: 0; }

form .MuiGrid-root > .MuiGrid-item {
  padding-top: 0;
  padding-bottom: 0; }

.MuiFormLabel-root {
  max-width: calc(100% - 84px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media (max-width: 650px) {
    .MuiFormLabel-root {
      max-width: calc(96%);
      white-space: unset;
      font-size: 0.9rem !important; } }

.flexContainerForButton {
  display: flex; }
  .flexContainerForButton > * {
    width: auto;
    flex: 1;
    margin: 0;
    align-items: center; }
  .flexContainerForButton button {
    margin: 0; }
  .flexContainerForButton > span {
    font-size: 16px;
    color: #517085; }
    .flexContainerForButton > span a {
      color: inherit;
      text-decoration: underline; }

.MuiFormLabel-root.Mui-error {
  color: #666666 !important; }

.MuiFormLabel-root.Mui-focused {
  color: #373733 !important; }

a {
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

.MuiSelect-select.Mui-disabled {
  background-color: #f9f9f9; }

.MuiButton-contained.Mui-disabled {
  background-color: #c6c6cc !important; }

.MuiFormHelperText-root {
  color: #ea2b1e !important;
  font-family: "Open Sans", sans-serif; }

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: rgba(194, 194, 194, 0.56) !important; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.linkContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 42px;
  margin-bottom: 42px; }
  .linkContainer > div {
    width: auto;
    margin: 0; }

.mb-25 {
  margin-bottom: 25px !important; }

.mt-25 {
  margin-top: 25px !important; }

.paper {
  border-radius: 8px;
  box-shadow: 0 5px 21px 0 rgba(0, 0, 0, 0.03);
  border: solid 1px #e8e8e8; }

.centeredText {
  text-align: center; }

._react_fileupload_form_content {
  width: 130px;
  height: 32px;
  border-radius: 21px;
  border: solid 1px #373737;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #373737;
  cursor: pointer;
  line-height: 2.2; }

#Progress_Status {
  width: 50%;
  background-color: #e8e8e8; }

#myProgressBar {
  height: 4px;
  border-radius: 3px;
  text-align: center;
  line-height: 32px;
  color: black; }

#Progress_Status,
#progressStatus {
  display: inline-block; }

#progressStatus {
  padding-left: 11px; }

h1 {
  font-size: 48px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #373737; }

h2 {
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #373737; }

h3 {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #373737; }

.bodyBold {
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #373737; }

.body {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #373737; }

.text {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #373737; }

.smallText {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #373737; }

.petitText {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #373737; }

.link {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: right;
  color: #373737; }

.toggleSelected {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #373737; }

.toggleNotSelected {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #373737; }

.MuiListItemText-root {
  text-overflow: ellipsis;
  overflow: hidden; }

.MuiOutlinedInput-inputSelect {
  padding-right: 56px !important; }

.hidden {
  max-height: 0;
  overflow: hidden; }

[aria-disabled="true"] {
  opacity: 0.6; }

.show-on-mobile {
  display: none; }

.display-none {
  display: none; }

.accordion .panel {
  box-shadow: none; }
  .accordion .panel .MuiExpansionPanelSummary-root {
    padding: 0px;
    min-height: auto; }
    .accordion .panel .MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content {
      margin: 0px; }

.smallIcon {
  height: 25px;
  width: 25px; }

@media (max-width: 1023px) and (max-device-width: 1023px) {
  .hide-on-mobile {
    display: none !important; }
  .show-on-mobile {
    display: block; } }
